import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ContextService } from './services/context.service';
import { Events } from './shared/consts/events';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'iTeroAnalyticsDashboard';

  constructor(private router: Router,
    private contextService: ContextService,
    private translateService: TranslateService) {}

  ngOnInit(): void {
    this.addEvents();
    this.getContext();
  }

  addEvents(){
    window.addEventListener('message', (event) => {
      if (event.data?.eventName === Events.InitContext) {
        this.setLanguage(event.data.payload.selectedLanguage);
        this.contextService.initialize(event.data.payload);
        this.router.navigate(['/clinic']);
      } else if (event.data?.eventName === Events.CompanyChanged) {
        this.contextService.updateCompany(event.data.payload.company);
      } else if (event.data?.eventName === Events.LanguageChanged) {
        this.setLanguage(event.data.payload.language);
      }
    });
  }

  getContext() {
    const eventMessage = {
      eventName: Events.GetContext,
      appName: Events.AppName
    };
    window.parent.postMessage(eventMessage, '*');
  }

  setLanguage(lang: string) {
      this.translateService.use(lang).subscribe(() => {
          this.contextService.selectedLanguage$.next(lang);
      });
  }
}
