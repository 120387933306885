import { Pipe, PipeTransform } from '@angular/core';
import { Utils } from '../../services/utils.service';
import { ContextService } from 'src/app/services/context.service';

@Pipe({
	name: 'dateFormatter'
})
export class DateFormatterPipe implements PipeTransform {
	constructor(private contextService: ContextService) {}

	transform(stringToFormat: string): string {
    	const dateFormat = (this.contextService.dateFormat);
		if(stringToFormat){
			return Utils.formatDate(stringToFormat, dateFormat);
		}
		return '';
	}
}
