import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module'; 
import { SharedModule } from './shared/shared.module';
import { ContextService } from './services/context.service';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { HttpHandlerInterceptorService } from './interceptors/http-handler.interceptor';
import { AppConfigService } from './services/app-config.service';
import { initializeAppConfig } from './app-initialize';
import { AnalyticsBffConnectionService } from './services/api/analytics-bff-connection.service';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    SharedModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
    }
    })
  ],
  providers: [
    {
			provide: APP_INITIALIZER,
			multi: true,
			deps: [AppConfigService],
			useFactory: initializeAppConfig
		},
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpHandlerInterceptorService,
      multi: true
    },
    AnalyticsBffConnectionService,
    ContextService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
