import { Injectable } from '@angular/core';
import moment from 'moment';
import { TranslateService } from '@ngx-translate/core';
import { Colors } from './../shared/consts/colors';

@Injectable()
export class Utils {

    constructor(private translateService: TranslateService) {
    }

    public static formatString(source: string, ...params: any[]): string {
        return source.replace(/{(\d+)}/g, (match, num) => {
            return typeof params[num] !== 'undefined' ? params[num] : match;
        });
    }

    public static formatDate(value: string, dateFormat: string): string {
        const defaultFormat = 'MM/DD/YYYY';
        const format = dateFormat || defaultFormat;
        return moment(value).format(format);
    }

    public CreateMonthsArray(): string[] {
        var months: string[] = [];
        months.push(this.translateService.instant('Months.January'));
        months.push(this.translateService.instant('Months.February'));
        months.push(this.translateService.instant('Months.March'));
        months.push(this.translateService.instant('Months.April'));
        months.push(this.translateService.instant('Months.May'));
        months.push(this.translateService.instant('Months.June'));
        months.push(this.translateService.instant('Months.July'));
        months.push(this.translateService.instant('Months.August'));
        months.push(this.translateService.instant('Months.September'));
        months.push(this.translateService.instant('Months.October'));
        months.push(this.translateService.instant('Months.November'));
        months.push(this.translateService.instant('Months.December'));
        return months;
    }

    public static colorGradient(count: number, data: any[]) {
        const startIndex = data.length;
        const num = count - startIndex;
        const gradientFromRGB = Colors.Main3.match(/\d+/g)!.map(Number);
        const gradientToRGB = Colors.Main4.match(/\d+/g)!.map(Number);

        const rRange = Math.floor(
            (gradientToRGB[0] - gradientFromRGB[0]) / num
        );
        const gRange = Math.floor(
            (gradientToRGB[1] - gradientFromRGB[1]) / num
        );
        const bRange = Math.floor(
            (gradientToRGB[2] - gradientFromRGB[2]) / num
        );
        let r = gradientToRGB[0];
        let g = gradientToRGB[1];
        let b = gradientToRGB[2];
        for (let i = count - 1; i >= startIndex; i--) {
            data[i] = `rgb(${r}, ${g}, ${b})`;
            r -= rRange;
            g -= gRange;
            b -= bRange;
        }
    }
}
