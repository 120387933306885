import { HttpClient, HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { BehaviorSubject, catchError, filter, finalize, Observable, switchMap, take, throwError } from 'rxjs';
import { Injectable } from '@angular/core';
import { ContextService } from '../services/context.service';
import { Utils } from '../services/utils.service';
import { Events } from '../shared/consts/events';

@Injectable({
	providedIn: 'root'
})
export class HttpHandlerInterceptorService implements HttpInterceptor {
	isRefreshingToken: boolean = false;
    tokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
	
	constructor(private http: HttpClient,
		private contextService: ContextService) {}

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const finalRequest = request.clone({
            headers: request.headers.set('Authorization', `Bearer ${this.contextService.accessToken}`)
		});
		return next.handle(finalRequest).pipe(
			catchError(error => {
				if(error instanceof HttpErrorResponse && (<HttpErrorResponse>error).status === 401 && !request.url.includes('/v1/auth/session/refresh')) {
					return this.handle401Error(request, next);
				} else {
					return throwError(() => error);
				}
			}));
	}

	handle401Error(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (!this.isRefreshingToken) {
            this.isRefreshingToken = true;
            // Reset here so that the following requests wait until the token
            // comes back from the refreshToken call.
            this.tokenSubject.next(null);

            return this.refreshToken().pipe(
                switchMap((newToken: any) => {
                    if (newToken) {
                        this.tokenSubject.next(newToken.accessToken);
						const finalRequest = req.clone({
							headers: req.headers.set('Authorization', `Bearer ${newToken.accessToken}`)
						});
                        return next.handle(finalRequest);
                    }
                    return this.logout();
                }),
                catchError(error => {
                    return this.logout();
                }),
                finalize(() => {
                    this.isRefreshingToken = false;
                }))
        } else {
            return this.tokenSubject.pipe(
                filter(token => token != null),
                take(1),
                switchMap(token => {
					const finalRequest = req.clone({
						headers: req.headers.set('Authorization', `Bearer ${token}`)
					});
                    return next.handle(finalRequest);
                }));
        }
    }

	refreshToken(): Observable<string> {
		return this.http.get<any>(Utils.formatString(this.contextService.refreshTokenUrl, this.contextService.sessionId, 'itero-analytics'), { headers: {
			'Authorization': `Bearer ${this.contextService.accessToken}`
		}});
	}

	logout() {
		const sessionId = this.contextService.sessionId;
		const eventMessage = {
			eventName: Events.TokenExpired,
			appName: Events.AppName,
			payload: {
				sessionId
			}
		};
		window.parent.postMessage(eventMessage, '*');
		return throwError(() => new Error());
	}
}
