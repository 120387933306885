export class ApiRoutes {
  public static Clinic = class {
    private static domain = 'v1/clinic';
    static getOrders = (companyId: string) => `${ApiRoutes.Clinic.domain}/get-orders?companyId=${companyId}`;
    static getProcedures = (companyId: string, lang: string) => `${ApiRoutes.Clinic.domain}/get-procedures?companyId=${companyId}&lang=${lang}`;
    static getFeatures = (companyId: string) => `${ApiRoutes.Clinic.domain}/get-features?companyId=${companyId}`;
    static getTreatments = (companyId: string, lang: string) => `${ApiRoutes.Clinic.domain}/get-treatments?companyId=${companyId}&lang=${lang}`;
    static getScanningTime = (companyId: string) => `${ApiRoutes.Clinic.domain}/get-scanning-time?companyId=${companyId}`;
    static getTreatmentConversionRate = (companyId: string) => `${ApiRoutes.Clinic.domain}/get-treatment-conversion-rate?companyId=${companyId}`;
  }
  
  public static History = class {
    private static domain = 'v1/history';
    static getSyncDateTime = () => `${ApiRoutes.History.domain}/sync-date-time`;
  }
}