import { NgModule } from '@angular/core';
import { ITeroOrdersComponent } from './i-tero-orders/i-tero-orders.component';
import { MatGridListModule } from '@angular/material/grid-list';
import { HighchartsChartModule } from 'highcharts-angular';
import { ScanningTimeComponent } from './scanning-time/scanning-time.component';
import { BarAlignLeftChartComponent } from './bar-align-left-chart/bar-align-left-chart.component';
import { DateFormatterPipe } from './pipes/dateFormatter.pipe';
import { DashboardTopBarComponent } from './dashboard-top-bar/dashboard-top-bar.component';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';

@NgModule({
  providers: [
    DateFormatterPipe,
  ],
  declarations: [
    ITeroOrdersComponent,
    ScanningTimeComponent,
    BarAlignLeftChartComponent,
    DashboardTopBarComponent,
    DateFormatterPipe
  ],
  imports: [
    CommonModule,
    HighchartsChartModule,
    MatGridListModule,
    TranslateModule
  ],
  exports: [
    ITeroOrdersComponent,
    ScanningTimeComponent,
    BarAlignLeftChartComponent,
    DashboardTopBarComponent,
    DateFormatterPipe,
    TranslateModule
  ]
})
export class SharedModule { }
