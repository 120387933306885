import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConfigService } from '../app-config.service';
import { ApiRoutes } from './api-routes.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class AnalyticsBffConnectionService {
    constructor(private http: HttpClient,
        private appConfigService: AppConfigService,
        private translateService: TranslateService) {
        }

    getOrdersChart(companyId: string, options?: any): Observable<any> {
        const apiUrl = this.appConfigService.appSettings.analyticsEndpoint.concat(ApiRoutes.Clinic.getOrders(companyId));
        return this.http.get(apiUrl, { ...options, withCredentials: true });
    }

    getProceduresChart(companyId: string, options?: any): Observable<any> {
        const apiUrl = this.appConfigService.appSettings.analyticsEndpoint.concat(ApiRoutes.Clinic.getProcedures(companyId, this.translateService.currentLang));
        return this.http.get(apiUrl, { ...options, withCredentials: true  });
    }

    getFeaturesChart(companyId: string, options?: any): Observable<any> {
        const apiUrl = this.appConfigService.appSettings.analyticsEndpoint.concat(ApiRoutes.Clinic.getFeatures(companyId));
        return this.http.get(apiUrl, { ...options, withCredentials: true  });
    }

    getTreatmentsChart(companyId: string, options?: any): Observable<any> {
        const apiUrl = this.appConfigService.appSettings.analyticsEndpoint.concat(ApiRoutes.Clinic.getTreatments(companyId, this.translateService.currentLang));
        return this.http.get(apiUrl, { ...options, withCredentials: true  });
    }

    getScanningTimeChart(companyId: string, options?: any): Observable<any> {
        const apiUrl = this.appConfigService.appSettings.analyticsEndpoint.concat(ApiRoutes.Clinic.getScanningTime(companyId));
        return this.http.get(apiUrl, { ...options, withCredentials: true  });
    }

    getTreatmentConversionRateChart(companyId: string, options?: any): Observable<any> {
        const apiUrl = this.appConfigService.appSettings.analyticsEndpoint.concat(ApiRoutes.Clinic.getTreatmentConversionRate(companyId));
        return this.http.get(apiUrl, { ...options, withCredentials: true  });
    }
    
    getSyncDateTime(options?: any): Observable<any> {
        const apiUrl = this.appConfigService.appSettings.analyticsEndpoint.concat(ApiRoutes.History.getSyncDateTime());
        return this.http.get(apiUrl, { ...options, withCredentials: true  });
    }
}