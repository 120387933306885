import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, tap, map } from "rxjs";
import { AppConfig, AppSettings } from "../models/app-config";

@Injectable({
    providedIn: 'root'
  })
  export class AppConfigService {
    private appconfigRoute : string = '/assets/config/appSettings.json';
    private appconfigRouteMock : string ='/assets/config/appSettingsMock.json';
    private appConfig: AppConfig = new AppConfig();

    constructor(private http: HttpClient) { }
    
    loadAppConfig(): Observable<AppSettings> {
      const urlParams = new URLSearchParams(window.location.search);
      const useMock = urlParams.get('useMock')
		return this.http.get<AppSettings>(useMock ? this.appconfigRouteMock : this.appconfigRoute).pipe(
      tap(data => {
				this.appConfig.appSettings = data;
			}),
      map(res => res));
    }

    get appSettings(): AppSettings {
        if (!this.appConfig.appSettings) {
            throw Error('App Settings not loaded!');
        }
        return this.appConfig.appSettings;
    }
}