import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  { 
    path: 'clinic', 
    loadChildren: () => import('./clinic/clinic.module').then(m => m.ClinicModule)
  },
  { 
    path: 'lab', 
    loadChildren: () => import('./lab/lab/lab.module').then(m => m.LabModule)
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
