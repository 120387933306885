import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class ContextService {

    contextChanged = new BehaviorSubject<any>({});
    selectedLanguage$ = new BehaviorSubject<string>('');

    private _sessionId: string = ''
    private _accessToken: string = ''
    private _companyId: string = '';
    private _refreshTokenUrl: string ='';
    private _dateFormat: string = '';

    get sessionId(): string {
        return this._sessionId;
    }
    set sessionId(value: string) {
        this._sessionId = value;
    }
    get accessToken(): string {
        return this._accessToken;
    }
    set accessToken(value: string) {
        this._accessToken = value;
    }
    get companyId(): string {
        return this._companyId;
    }
    set companyId(value: string) {
        this._companyId = value;
    }
    get refreshTokenUrl(): string {
        return this._refreshTokenUrl;
    }
    set refreshTokenUrl(value: string) {
        this._refreshTokenUrl = value;
    }
    get dateFormat(): string {
        return this._dateFormat;
    }
    set dateFormat(value: string) {
        this._dateFormat = value;
    }

    constructor() {
    }

    initialize(payload: any){
        this.sessionId = payload.sessionId;
        this.accessToken = payload.accessToken;
        this.companyId = payload.selectedCompanyId;
        this.refreshTokenUrl = payload.refreshTokenUrl;
        this.dateFormat = payload.dateFormat;
        this.contextChanged.next({
            sessionId: payload.sessionId,
            accessToken: payload.accessToken,
            companyId: payload.selectedCompanyId,
            refreshTokenUrl: payload.refreshTokenUrl,
            dateFormat: payload.dateFormat
        });
    }

    updateCompany(companyId: string) {
        this.companyId = companyId;
        this.contextChanged.next({
            sessionId: this.sessionId,
            accessToken: this.accessToken,
            companyId: this.companyId,
            refreshTokenUrl: this.refreshTokenUrl,
            dateFormat: this.dateFormat
        });
    }
}
